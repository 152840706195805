<template>
  <div v-if="isMobile" class="burger-menu">
    <div v-if="areLinksVisible" class="links-mobile">
      <router-link to="/">Blog</router-link>
      <router-link to="/projects">Progetti</router-link>
      <router-link to="/contacts">Contatti</router-link>
      <router-link to="/secretpage">Il segreto della felicità</router-link>
    </div>
    <img
      style="width: 24px"
      v-if="!areLinksVisible"
      src="@/assets/icons/burger-menu.png"
      alt="open-menu"
      @click="toggleVisibility"
    />
    <img
      style="width: 24px; margin-top: var(--space-unit)"
      v-else
      src="@/assets/icons/x-menu.png"
      alt="close-menu"
      @click="toggleVisibility"
    />
  </div>
  <div v-else class="links">
    <router-link to="/">Blog</router-link>
    <router-link to="/projects">Progetti</router-link>
    <router-link to="/contacts">Contatti</router-link>
    <router-link to="/secretpage">Il segreto della felicità</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      areLinksVisible: false,
    };
  },
  methods: {
    toggleVisibility() {
      this.areLinksVisible = !this.areLinksVisible;
    },
  },
  beforeMount() {
    if (screen.width <= 1024) {
      this.isMobile = true;
    }
  },
};
</script>

<style>
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.links-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-unit);
}
</style>