const mdlHst = "https://backbone.n-bit.it/"

// Utility to parse the comment against xss and injections, plus against badwords
// Returns true if a profanity is found in the comment
function profanityFilter(comment) {
    if (comment.includes("<") || comment.includes(">")) {
        return true
    }
    return fetch(mdlHst + "phrase/" + comment)
        .then(resp => resp.json())
        .then(data => {
            if (data.ProfanitiesFound != 0) {
                return true
            } else {
                return false
            }
        })

}

// Utility to comunicate with the middleware to performe the captcha call
// Returns true / false based on the middleware response
function recaptchaFilter(token) {
    return fetch(mdlHst + "recaptcha/" + token)
        .then(resp => resp.json())
        .then(data => {
            if (data.Success === true) {
                return true
            } else {
                return false
            }
        })
}

// Calls the middleware to fetch all the blog posts.
// The respose is manipulated later in the component
function fetchList() {
    var out = []
    return fetch(mdlHst + "butterlist")
        .then(resp => { return resp.json() })

}

// Calls the middleware to fetch a single blogpost given by the slug
// The response is manipulated later in the component
function fetchPost(slug) {

    return fetch(mdlHst + "postcontent/" + slug)
        .then(resp => { return resp.json() })
}

// Calls the middleware to fetch the comments to the post
function fetchComments(slug) {
    return fetch(mdlHst + "fetchcomments/" + slug)
        .then(resp => resp.json())
        .then(data => { return data })
        .catch(err => console.log(err))
}

// Used to post comments to firebase
// Returns true or false based on middleware error writing to firebase:
// if the middleware returns an error, the function returns false
// if the middleware writes correctly, returns true
function postComment(slug, name, text) {
    return fetch(mdlHst + "postcomment", {
        method: "POST",
        body: JSON.stringify({
            "slug": slug,
            "name": name,
            "text": text
        })
    })
        .then(resp => resp.text())
        .then(data => { return data })
}


function getLikes(slug) {
    return fetch(mdlHst + "getlike/" + slug).then(resp => resp.text()).then(data => { return data })
}

// Used in blogpost to call the middleware and leave a like
function leaveLike(slug) {
    return fetch(mdlHst + "leavelike/" + slug).then(resp => resp.text()).then(data => { return data })
}

export { profanityFilter, recaptchaFilter, fetchList, fetchPost, fetchComments, postComment, leaveLike, getLikes }