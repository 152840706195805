<template>
  <div class="suggestedbooks-outer">
    <div class="suggestedbooks-caption">
      <h1>Consigli di lettura</h1>
      <p>
        Se vi servisse qualche suggerimento per una lettura interessante, eccovi
        serviti. <br />
        Non sono tutti libri di informatica, anzi. Sono semplicemente i libri
        che mi hanno più colpito e di cui mi fa sempre piacere parlare bene!
        Potete leggerne un estratto cliccando su 'preview'. Non sono link
        sponsorizzati! <br />
        Occhio che i link cambiano spesso, anche se tento di tenere il passo: se
        la preview non funziona, cercateli su Google. <br />
        In ultima, non tutti i libri sono stati tradotti in italiano.
      </p>
    </div>
    <div class="outer-cards">
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookOne = !bookOne;
            }
          "
        >
          <iframe
            class="suggestedbooks-frame"
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="10"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B07BMN2N6D&amp;preview=inline&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_WNATD8HPBJP9B7G3ZVTJ&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%"
          ></iframe>
          <p class="card-text" v-if="bookOne">
            E' un libro di dati ed evidenze, con le quali l'autore vuole
            smontare le convinzioni più radicate (ed errate) che tutti noi
            abbiamo riguardo al mondo in cui viviamo. Rosling racconta molte
            delle sue esperienze personali, maturate in anni di lavoro come
            medico e consulente medico in giro per il mondo, le quali tentano di
            dare un punto di vista diverso riguardo a molte tematiche spesso
            trattate superficialmente, come alfabetizzazione, fame nel mondo,
            scolarizzazione, libertà civili e molto altro. (Nota: il libro ha
            qualche anno ormai ma, anche se i dati potrebbero essere variati, vi
            assicuro che rimane comunque un ottimo libro.)
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookTwo = !bookTwo;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B0B544M8N4&amp;preview=newtab&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_ZDHE9W5B1V8MRSTQR6RR&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookTwo">
            Con più di trent'anni di esperienza nel campo della sicurezza
            informatica, Hypponen ci da il suo punto di vista in merito alla
            sicurezza delle tecnologie che utilizziamo tutti i giorni, da
            Internet, alla cyber-warfare, al mondo delle criptovalute e molto
            molto altro. Un libro per tutti però, scritto in maniera semplice e
            veloce, pieno di esempi e racconti personali dell'autore. Se volete
            sapere di più riguardo al mondo di dispositivi che ci circondano e
            di quanto in fretta possano diventare armi a doppio taglio, questo
            libro è assolutamente una lettura obbligatoria.
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookThree = !bookThree;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B0BWNS25Z8&amp;preview=newtab&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_PGH6QEJQ2QJ2SWZJA639&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookThree">
            Con i libri di Carlo Rovelli, si va ogni volta sul sicuro. Sono
            sempre una lettura fantastica, in cui ci si ritrova piacevolmente
            accompagnati in un viaggio attraverso concetti molto complessi in
            maniera semplice e comprensibile da tutti. Pur avendo letto quasi
            tutto di Rovelli, questo suo ultimo libro mi ha colpito
            particolarmente. Non tanto per la fisica in esso, ma perchè trasuda
            la voglia di raccontare la teoria che Rovelli sta sostenendo da una
            vita intera, ancora lontana purtroppo dall'essere provata. Se siete
            appassionati di fisica teorica (o di fantascienza, per il momento
            almeno) questo libro vale assolutamente ogni secondo speso a
            leggerlo.
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookFour = !bookFour;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B07M6YTMKC&amp;preview=newtab&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_ZMXE48HMHFJMWJ2WCC30&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookFour">
            Da studente (inizialmente, almeno) di economia, mi sembrava assurdo
            pensare che, per quanto chiari fossero i modelli mostrati sui libri,
            nella realtà poi andasse sempre tutto allo sfascio. Da bravi
            italiani ce lo diciamo sempre:"Eh ma in Italia ...". Ma perchè è
            cosi? Penso che Carlo Cottarelli, visioni politiche a parte, sia uno
            degli economisti più esperti e capaci in circolazione e che, con
            questo libro, abbia davvero centrato il punto. Una check-list che
            spiega perchè l'economia italiana è messa così male, dove e quali
            sono le cause e come, potenzialmente, risolverle. Qualcuno dovrebbe
            farlo leggere ai piani alti...
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookFive = !bookFive;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B06XBV7WW3&amp;preview=newtab&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_N0M5HVEFWM1V1JBHFXW4&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookFive">
            Edward Frenkel insegna teoria delle rappesentazioni, fisica
            matematica e geometria algebrica a Berkley e, con questo libro,
            racconta la sua vita e carriera da matematico, dalla giovane età
            alle ricerche più recenti. Per quento siano interessanti le
            tematiche matematiche trattate, quello che più mi spinge a
            consigliare il libro è la palese passione con il quale è stato
            scritto, che rende concetti molto complessi come le teorie di
            Langlands e la matematica dei loop davvero interessanti anche per
            chi, a prima vista, potrebbe esserne spaventato. Una lettura
            obbligatoria per tutti gli appassionati di matematica e, per chi non
            lo fosse, un invito a scoprire le bellezze di questo mondo.
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookSix = !bookSix;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B015DLA0LE&amp;preview=inline&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_HZ6QGRXB21FJ43Y3ZM5X&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookSix">
            La rivista Popular Science lo descrive come: 'un libro pratico e di
            grande intrattenimento', anche se, a mio avviso, è molto di più. Il
            libro prende a piene mani concetti di informatica, come gli
            algoritmi di ordinamento, di caching e la randomicità, e li lega a
            doppio filo ad esperienze che tutti noi possiamo osservare durante
            la nostra vita di tutti i giorni. Parla quindi anche di come le
            nostre vite siano inconsciamente guidate da algoritmi che nemmeno
            sappiamo di conoscere e di come questi plasmano le società in cui
            viviamo. Che siate appassionati di teoria degli algoritmi o meno è
            assolutamente un libro consigliato, anche solo per vedere le cose da
            un punto di vista diverso.
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
      <div class="cards-container">
        <div
          class="book"
          @click="
            {
              bookSeven = !bookSeven;
            }
          "
        >
          <iframe
            type="text/html"
            sandbox="allow-scripts allow-same-origin allow-popups"
            width="250"
            height="418"
            frameborder="0"
            allowfullscreen=""
            src="https://leggi.amazon.it/kp/card?asin=B0067K1WE0&amp;preview=newtab&amp;linkCode=kpe&amp;ref_=cm_sw_r_kb_dp_0J88W3C7R78TGYH476Q6&amp;hideBuy=true&amp;hideShare=true"
            style="max-width: 100%; border-radius: 32px"
          ></iframe>
          <p class="card-text" v-if="bookSeven">
            Un classico della 'fantascienza' (anche se tanto fanta non è).
            Abbott descrive l'esperienza di A. Square, ovvero un abitante di
            Flatlandia (un mondo in due dimensioni), mentre attraversa mondi con
            dimensioni diverse, come Linealandia e Puntolandia. Un libro si di
            fantasia, quindi, ma affrontata anche dal punto di vista della
            matematica che descrive come, in che misura e in che prospettiva le
            dimensioni possano interagire tra loro. Un classico, certo, ma che
            riprendo in mano ogni tanto sempre molto volentieri.
          </p>
          <p class="opener">Cliccami</p>
        </div>
      </div>
    </div>
    <p style="font-family:'Ubuntu-SemiBold;font-size: 30px">Buona lettura!</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bookOne: false,
      bookTwo: false,
      bookThree: false,
      bookFour: false,
      bookFive: false,
      bookSix: false,
      bookSeven: false,
    };
  },
};
</script>

<style scoped>
p {
  text-align: center;
}
.opener {
  writing-mode: vertical-rl;
  font-size: 20px;
  font-family: "Ubuntu-Bold";
  text-decoration: underline var(--color-main);
  letter-spacing: 4px;
  padding: 10px;
  cursor: pointer;
  width: fit-content;
}
.cards-container {
  max-width: 60%;
  display: flex;
  flex-direction: row;
  align-items: space-around;
}
.suggestedbooks-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-uunit);
}
.suggestedbooks-caption {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: var(--space-uunit);
  gap: var(--space-uunit);
}
.card-text {
  width: 80%;
  text-align: justify;
}
.outer-cards {
  width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--space-uunit);
}
.book {
  padding: var(--space-unit);
  cursor: pointer;
  background-color: var(--color-background);
  border-radius: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: var(--space-uunit);
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid var(--color-main);
  box-shadow: 2px 2px 15px var(--color-main);
}
.book-caption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: inherit;
  gap: var(--space-uunit);
}
</style>