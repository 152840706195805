<template>
  <div class="footer-container footer-text">
    <div class="info-container">
      <p class="footer-text">
        Il sito utilizza un sistema di conteggio degli accessi e tracciamento
        delle richieste a scopi statistici. Nessun dato personale o che possa
        essere di riferimento diretto o indiretto all'utente viene raccolto
        dall'applicazione. Non so chi tu sia e, a meno che non ti vada di fare
        un saluto, va benissimo cosi! <br />
        Icone ed emojis fornite da <a href="https://icons8.com/">Icons8.com</a>.
        Contatti e informazioni, Nicola Moro @
        <a href="mailto:nicola.moro@n-bit.it">nicola.moro@n-bit.it</a>
      </p>
      <router-link to="/gdpr" class="footer-text"
        >Gdpr, Privacy e Regole</router-link
      >
      <br />
      <p>Content provided with the wonderful help of</p>
      <a href="https://buttercms.com/"
        ><img class="butter-logo" :src="buttercmslogopath" alt="buttercms-logo"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isdark"],
  data() {
    return {
      buttercmslogopath: "",
    };
  },
  methods: {
    logoSwitcher() {
      if (this.isdark) {
        this.buttercmslogopath = require("@/assets/butter-y.png");
      } else {
        this.buttercmslogopath = require("@/assets/butter-b.png");
      }
    },
  },
  mounted() {
    this.logoSwitcher();
  },
  updated() {
    this.logoSwitcher();
  },
};
</script>

<style>
.footer-container {
  width: 98%;
  display: flex;
  gap: var(--space-unit);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--space-unit);
}
.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referers {
  min-width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.butter-logo {
  max-width: 30%;
}
.footer-text {
  text-align: center;
  font-size: 10px;
}
</style>