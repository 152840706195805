<template>
  <div class="comment-card-outer">
    <div class="user-image">
      <img :src="parseImage()" alt="usericon" />
    </div>
    <div class="name-text">
      <h3>{{ comment.Name }}</h3>
      <p>{{ comment.Text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
  methods: {
    parseImage() {
      return require("@/assets/users/" + this.comment.Img + ".png");
    },
  },
};
</script>

<style>
.comment-card-outer {
  display: flex;
  gap: var(--space-unit);
  background-color: var(--color-comment-card);
  border-radius: 16px;
  border: 1px solid var(--color-main);
  padding: 1%;
  box-shadow: 2px 2px 5px var(--color-main-shadow);
}
.name-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-unit);
}
</style>