<template>
  <div class="home-container" v-if="!isMobile">
    <BlogList style="width: 76%"></BlogList>
    <WhoAmI style="width: 23%"></WhoAmI>
  </div>
  <div class="home-container-mobile" v-else>
    <WhoAmI style="width: 99%"></WhoAmI>
    <div class="separator"></div>
    <BlogList style="width: 99%"></BlogList>
  </div>
</template>

<script>
// @ is an alias to /src
import BlogList from "@/components/BlogList.vue";
import WhoAmI from "@/components/WhoAmI.vue";
import { useSeoMeta } from "unhead";

useSeoMeta({
  ogTitle: "N-bit.it",
  ogDescription: "N-bit.it - Nicola Moro",
  ogImage: "@/assets/nbitlogoday.webp",
});

export default {
  name: "HomeView",
  components: {
    BlogList,
    WhoAmI,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  beforeMount() {
    if (screen.width <= 768) {
      this.isMobile = true;
    }
  },
};
</script>

<style>
ul {
  max-height: 150vh;
  overflow-y: scroll;
}
.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2%;
}
.home-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2%;
}
</style>