<template>
  <div class="header-div">
    <div class="logo-div">
      <img
        v-if="isDark"
        class="logo"
        src="@/assets/nbitlogonight.webp"
        alt=""
      />
      <img v-else class="logo" src="@/assets/nbitlogoday.webp" alt="" />
      <h1 class="title">n-bit.it</h1>
    </div>
    <p class="bright-text">Nicola Moro</p>
    <HeaderLinks></HeaderLinks>
    <div class="separator"></div>
  </div>
</template>


<script>
import { useDark } from "@vueuse/core";
import HeaderLinks from "./HeaderLinks.vue";
export default {
  components: { HeaderLinks },
  data() {
    return {
      isDark: useDark(),
    };
  },
};
</script>

<style scoped>
img {
  width: 10%;
}

h1 {
  font-size: 3.5em;
}

.header-div {
  display: flex;
  flex-direction: column;
  gap: var(--space-unit);
  justify-content: center;
  align-items: center;
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>