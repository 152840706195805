import { createApp, onMounted } from 'vue'
import App from './App.vue'
import router from './router'
import GlobalCSS from "@/assets/global.css"
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { VueMathjax } from 'vue-mathjax-next'
import { createHead, VueHeadMixin } from "@unhead/vue";

const head = createHead()

createApp(App)
    .mixin(VueHeadMixin)
    .use(head)
    .use(router)
    .use(VueMathjax)
    .use(VueReCaptcha, { siteKey: "6LeTMu8pAAAAAKGtN-8hGqIfEFHTDMN8T2YN_gxb" })
    .mount('#app')