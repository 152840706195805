<template>
  <div class="outer-container">
    <ul>
      <input
        v-model="filter"
        type="text"
        placeholder="Cerca titolo..."
        @input="filterDocuments(filter)"
      />
      <li v-for="m in metadata" :key="m.slug">
        <router-link
          class="blogpost-router"
          :to="{ name: 'BlogPost', params: { slug: m.slug } }"
        >
          <div class="blogpost-container">
            <div
              class="cover-image"
              :style="{
                'background-image': compileImagePath(m.featured_image),
                'background-size': '100%',
                'background-repeat': 'no-repeat',
                width: '40%',
                maxHeight: '100%',
                'border-radius': '16px',
              }"
            ></div>

            <div class="caption-container">
              <h2>{{ m.title }}</h2>
              <p class="snippet">{{ m.summary }}</p>
              <div class="tags-container">
                <p>Parla di:</p>
                <p class="tags-card" v-for="c in m.tags" :key="c">
                  {{ c.name }},
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>


<script>
import { fetchList } from "@/utils/utils";

export default {
  data() {
    return {
      metadata: [],
      filter: "",
    };
  },
  methods: {
    compileImagePath(path) {
      return "url(" + path + ")";
    },
    collectDocuments() {
      fetchList().then((data) => (this.metadata = data["data"]));
    },
    filterDocuments(filter) {
      if (filter == "") {
        this.collectDocuments();
        return;
      }
      this.metadata = this.metadata.filter((word) =>
        word.title.toLowerCase().includes(filter.toLowerCase())
      );
    },
  },
  mounted() {
    this.collectDocuments();
  },
};
</script>

<style scoped>
ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  width: 100%;
  padding-inline-start: 0px;
  border-right: 1px solid var(--color-main);
  padding-right: var(--space-uunit);
}

input {
  margin-bottom: var(--space-uunit);
}

@keyframes hoveranimation {
  100% {
    border: 2px solid var(--color-end-gradient);
    background-image: linear-gradient(
      155deg,
      var(--color-start-gradient) 28%,
      var(--color-end-gradient) 97%
    );
    box-shadow: unset;
    padding: var(--space-uunit);
  }
}
li {
  border: 1px solid var(--color-main);
  width: auto;
  margin-bottom: var(--space-unit);
  min-height: 200px;
  max-height: 250px;
  border-radius: 16px;
  padding: var(--space-unit);
  box-shadow: 3px 3px 10px rgb(61, 59, 80);
  cursor: pointer;
  overflow: hidden;
}
li:hover {
  max-height: unset;
  animation: hoveranimation;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.outer-container {
  margin-top: var(--space-unit);
  margin-left: 1%;
  display: flex;
  flex-direction: column;
}

.blogpost-container {
  gap: var(--space-uunit);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: initial;
  min-height: 200px;
}

.blogpost-container:hover {
  padding-top: var(--space-unit);
  padding-bottom: var(--space-unit);
  gap: var(--space-unit);
}
.blogpost-router {
  max-height: 99%;
}
img {
  max-height: 90%;
  max-width: 90%;
  border-radius: 10px;
  object-fit: contain;
}

.cover-image {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
  background-size: cover;
  overflow: hidden;
  border-radius: 16px;
}

.caption-container {
  gap: 10px;
  width: 80%;
  display: flex;
  flex-wrap: nowrap;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  overflow: hidden;
}

.snippet {
  display: list-item;
  text-align: justify;
  font-size: 14px;
  overflow: hidden;
  font-family: "Ubuntu-Regular";
}

.tags-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.tags-card {
  border: unset;
  font-family: "Ubuntu-Regular";
}

@media (max-width: 768px) {
  h2 {
    font-size: 14px;
  }
  li {
    padding: auto;
    width: 90%;
  }
  ul {
    border: unset;
    padding: unset;
  }
  input {
    width: unset;
  }
  .outer-container {
    border: unset;
    display: flex;
    width: 99%;
    flex-direction: column;
  }
  .blogpost-router {
    height: fit-content;
    width: 100%;
  }
  .blogpost-container {
    width: 100%;
    margin: unset;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .caption-container {
    gap: unset;
    width: 99%;
    display: flex;
    flex-direction: column;
  }
  .snippet {
    font-size: 12px;
  }
  .tags-container {
    visibility: hidden;
  }
  .cover-image {
    width: unset;
    justify-content: center;
    border-radius: 10px;
    background-size: cover;
    border-radius: 16px;
    height: 75px;
  }
}
@media (max-width: 320px) {
  li {
    width: 80%;
  }
}
</style>