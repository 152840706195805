<template>
  <div class="contacts-outer">
    <p class="contacts-paragraph">
      Hai domande o vuoi informazioni? Ti va di fare un saluto?
    </p>
    <div class="contacts-container">
      <Socials></Socials>
    </div>
    <p class="contacts-paragraph">
      Oppure scrivimi a
      <a href="mailto:nicola.moro@n-bit.it">nicola.moro@n-bit.it</a>
    </p>
    <p style="margin-bottom: var(--space-uuunit)">
      Rispondo sempre a tutti, basta un po' di pazienza!
    </p>
  </div>
</template>

<script>
import Socials from "@/components/Socials.vue";
export default {
  components: {
    Socials,
  },
  data() {
    return {
      inputfield: "",
      isMailOk: true,

      textfield: "",
    };
  },
  methods: {
    // Checks for mail against regex pattern and updates isMailOk variable
    mailValidation(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isMailOk = emailRegex.test(email);
    },
  },
};
</script>

<style scoped>
p {
  text-align: center;
}
.contacts-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contacts-paragraph {
  text-align: center;
  margin-top: 25px;
}
.contacts-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-unit);
  align-content: center;
  justify-content: center;
}
.contacts-form {
  width: 40%;
  gap: var(--space-unit);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-image {
  width: 10%;
}
.error-p {
  font-size: 10px;
  font-family: "Ubuntu-Regular";
  color: crimson;
}
</style>