<template>
  <div class="gdpr-outer">
    <h1>Quindi ti interessa la privacy...</h1>
    <img class="ninjaimg" src="@/assets/ninjaimg.webp" alt="IncognitoMan" />
    <h2>E fai bene direi!</h2>
    <p style="text-align: center">
      Cliccare a caso sui siti senza leggere porta a un sacco di grane ...
      Perciò facciamo chiarezza!
    </p>
    <h1>Cookies</h1>
    <p>
      I cookies sono piccoli file di testo che il tuo browser conserva durante
      la navigazione. Se premi F12, fai clic su "Application"/"Applicazione" e
      cerchi nella sezione "Storage" li trovi tutti li. E possono essere davvero
      tanti! Il sito con cui stai interagendo utilizza questi cookies per
      rendere l'esperienza interattiva e personalizzata: ad esempio, quando
      effettui il login su un sito, il browser conserva l'informazione del
      login. In questo modo, il sito può vedere che hai effettuato il login,
      senza chiederti di rieffettuarlo ogni volta che esci e rientri.
    </p>
    <p>
      Tutto bello, fino a che non arrivano i pubblicitari che dicono: "Hey!
      Perchè non usiamo i cookies per tracciare cosa interessa all'utente e
      vendiamo queste informazioni alle aziende interessate? Ecco magari anche
      no: l'ultima volta che ho cercato una paperella di gomma ho visto così
      tanta pubblicità che sono quasi diventato un allevatore di oche...
    </p>
    <p>
      Quindi che si fa? Facciamo che io non li utilizzo. Sono costretto dalla
      normativa ad utilizzare un singolo cookie per il tracciamento del consenso
      GDPR, e uno per gestire la modalità notte (quanto è comoda però, eh?). Ma
      poi basta: se ne trovate altri non sono roba mia!
    </p>
    <p>
      <b>
        Ci tengo quindi a precisare che questo sito non utilizza cookies di
        tracciamento a scopi di marketing o pubblicità e nessun soggetto terzo
        ha avuto, ha o avrà accesso ad alcun cookie gestito da questo sito
        (perchè non ce ne sono, più che altro...).
      </b>
    </p>
    <h1>Requests tracking</h1>
    <p>
      Questo sito è fondamentalmente un blog, quindi: indovinate un po' in cosa
      spera chi gestisce un blog? Esatto, spera che la gente lo legga. Oltre a
      questo però, se ci fosse la possibilità di sapere a quali contenuti è più
      interessato chi legge sarebbe una grande cosa: giorni e orari di lettura
      preferiti, dispositivi su cui viene letto il blog e contenuti più letti
      sono dati essenziali per chi gestisce un blog. Per questo motivo, il sito
      provvede a raccogliere alcuni dati minimi di navigazione, elencati qui di
      seguito:
    </p>
    <ul>
      <li>Data e ora della connessione</li>
      <li>Indirizzo remoto (remoteAddress)</li>
      <li>Pagina richiesta (requestURI)</li>
      <li>Referral (header["Referer"])</li>
      <li>Piattaforma utilizzata (header["Sec-Ch-Ua-Platform"])</li>
      <li>Dispositivo mobile (header["Sec-Ch-Ua-Mobile"])</li>
      <li>Browser utilizzato (User Agent) (header["User-Agent"])</li>
    </ul>
    <p>
      Questi dati vengono raccolti ogni volta che visualizzi una pagina o un
      articolo del blog. E' anche presente un banner (piccolo perchè è orribile,
      ma tant'è) sul fondo di ogni pagina per ricordartelo. Servono
      ESCLUSIVAMENTE a scopi statistici. Da questi dati inoltre io non ho modo
      di sapere chi tu sia, il tuo nome o cognome, indirizzo fisico o altre
      informazioni a te direttamente o indirettamente collegate. Quasi la
      totalità dei siti che visiti ogni giorno raccoglie queste informazioni,
      spesso anche per scopi commerciali. Questo sito utilizza i dati raccolti
      solo per fini statistici (insomma dovrò pure gestirlo sto blog no?).
    </p>
    <p>
      Piccola nota per quanto riguarda l'Indirizzo Remoto (IP): tracciare questo
      dato in particolare mi permette di avere una minima idea dell'area
      geografica da cui leggi il blog. Attenzione:
      <b>area geografica</b>: non indirizzo, regione, provincia, comune e via.
      Non ho alcun modo di ricavare tali informazioni da un dato come
      l'indirizzo IP, a prescindere che tu utilizzi un indirizzo statico o
      dinamico. Quello che posso capire da questo dato, in buona sostanza, è se
      sei più vicino al Messico o alla Cina. Ci tengo a precisare questo perchè
      un sacco di
      <a
        href="https://eur-lex.europa.eu/legal-content/IT/TXT/HTML/?uri=CELEX%3A31995L0046"
        >normative</a
      >
      sono discordanti sul fatto che l'indirizzo IP dinamico sia o meno un dato
      personale. Tecnicamente non dovrebbe esserlo, perchè non è possibile
      risalire alla persona dall'indirizzo IP, salvo avere i mezzi per collegare
      tale indirizzo all'intestatario della connessione. Io però non sono
      Telecom: non posso farlo e nemmeno mi interessa.
    </p>
    <p>
      <b>
        Concludendo quindi: non raccolgo questi dati a fini commerciali,
        solamente a fini statistici e organizzativi per il blog. Nessun soggetto
        terzo ha accesso a questi dati ed è possibile, in ogni caso, richiederne
        la cancellazione, modifica o visura.
      </b>
    </p>
    <h1>Google reCaptcha</h1>
    <p>
      Il sito utilizza il sistema reCaptcha, fornito da Google, per il
      tracciamento della potenziale attività di bot sul sito: questo avviene
      esclusivamente sugli articoli. In pratica, il sito utilizza un sistema di
      riconoscimento, analisi e scoring dell'attività dell'utente sull'articolo
      del blog visitato. Nel caso in cui l'utente non sia umano (ovvero sia un
      bot o un sistema di richieste automatizzato di qualche tipo), il sistema
      reCaptcha lo riconosce e impedisce l'inserimento di commenti. Questo non
      perchè voglia sapere se siete umani o meno, ma perchè, creando il bot
      giusto, è possibile inserire migliaia di commenti al secondo, rendendo
      l'attività del mio povero server troppo sfiancante da sostenere.
    </p>
    <p>
      Se visitate un articolo e guardate in basso a destra, noterete un piccolo
      quadratino con tre frecce disposte a cerchio. Quello indica che il sistema
      reCaptcha è attivo e funzionante. Nel caso vogliate saperne di più sul
      funzionamento di reCaptcha, vi invito a cliccare su quel simbolo e leggere
      le note sulla privacy. Altrimenti, potete trovare informazioni utili
      direttamente sulla
      <a href="https://www.google.com/recaptcha/about/">pagina dedicata</a> di
      Google.
    </p>
    <h1>Dove sono i miei dati, Summer?</h1>
    <p>
      Come disse il vecchio saggio, "Il modo migliore per non avere problemi è
      non farsene". La sicurezza dei dati è super importante e la gestione di
      tale sicurezza è una materia che diventa di giorno in giorno più
      complessa. Io, nel mio piccolo, non ho a disposizione ne le infrastrutture
      ne le conoscenze specifiche in materia per poterli gestire in totale
      sicurezza. In sostanza, ragazzi, non posso semplicemente salvarli su un
      hard disk a casa, chiuderlo in un cassetto e dire "bene, state li e non vi
      muovete". Non funziona così.
      <br />Per lo stesso motivo, e per evitare possibili (anche se improbabili)
      attacchi informatici verso questo sito, lo stesso sito risiede
      materialmente (è hostato) su un server virtuale gestito da un'azienda di
      web e virtual server hosting, azienda che sicuramente è molto più in grado
      di me di gestire la sicurezza di ogni possibile dato che passa per il
      blog.
    </p>
    <p>
      Per legge, devo informarvi che i dati di navigazione raccolti non sono
      fisicamente in mano mia (ma d'altro canto, ormai niente è più fisicamente
      nella mani di nessuno, pensate a drive, dropbox, ecc...). Quindi significa
      che questa azienda
      <b>può vedere i miei dati</b>? La risposta è: <b>NO</b>. Anche se il
      server è mantenuto da loro, il gestore del server (che sarei io) rimane
      comunque l'unico titolato all'accesso.
    </p>
    <h1>Chi gestisce i miei dati?</h1>
    <p>
      Il gestore dei dati sono io (chi l'avrebbe mai detto?). Di seguito i
      contatti dettagliati per richiedere supporto in merito:
    </p>
    <p>
      Nicola Moro
      <br />Email 1: nicola.moro@n-bit.it <br />Email2 : nicola.moro@live.it
    </p>
    <p>
      Per ogni necessità o evenienza non esitare a scrivermi e, finalmente,
      buona navigazione!
    </p>
    <p style="font-size: 10px">
      <i>
        Copertina:
        <a
          href="https://giphy.com/gifs/walkofftheearth-walk-off-the-earth-3ohhwytHcusSCXXOUg"
          >Ninja Spy GIF by Walk Off The Earth on Giphy</a
        >
      </i>
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit("close");
  },
  unmounted() {
    if (!document.cookie.includes("gdpr=true")) {
      this.$emit("showBanner");
    }
  },
};
</script>

<style scoped>
p {
  width: 99%;
  text-align: justify;
}

ul {
  list-style: none;
  margin: none;
}
.gdpr-outer {
  margin-top: var(--space-uunit);
  display: flex;
  flex-direction: column;
  gap: var(--space-uunit);
  align-items: center;
}
.ninjaimg {
  border: 5px solid var(--color-main);
  width: 25%;
  border-radius: 32px;
}
</style>