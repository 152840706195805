<template>
  <div class="notfound-container">
    <div class="title-container">
      <h1 class="title">404</h1>
      <img class="gif" src="@/assets/404.gif" alt="" />
    </div>
    <p>Quello che stai cercando non è più qui o non esiste.</p>
    <p style="font-size: 10px">
      <i
        >Vincent Vega on
        <a
          href="https://tenor.com/view/vincent-vega-pulp-fiction-what-where-john-travolta-gif-15941212"
          >Tenor</a
        ></i
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.title {
  font-size: 7em;
}
.title-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.notfound-container {
  margin-top: var(--space-unit);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gif {
  margin-top: var(--space-unit);
  width: 65%;
  border: 10px solid var(--color-main);
  border-radius: 120px;
  box-shadow: 5px 5px 10px var(--color-main-shadow);
}
</style>