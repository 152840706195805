<template >
  <div
    v-if="!mobile"
    class="player-container"
    @mouseenter="isPlayerOpen = true"
    @mouseleave="isPlayerOpen = false"
  >
    <img
      v-show="!isPlayerOpen"
      class="spotify-logo"
      @click="togglePlayer"
      src="@/assets/icons/spotifyicon-open.webp"
      alt="spotify-icons-open"
    />
    <iframe
      v-show="isPlayerOpen"
      style="border-radius: 12px"
      src="https://open.spotify.com/embed/playlist/3shsB2y2H487Xptz9wUXTQ?utm_source=generator&theme=0"
      width="100%"
      height="152"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    ></iframe>
    <p
      v-if="isPlayerOpen"
      style="font-size: 8px; margin-left: 5px; color: #999"
    >
      Per ascoltare le canzoni in versione integrale, esegui l'accesso a Spotify
    </p>
  </div>

  <div>
    <img
      v-if="this.isDark"
      class="candle-img"
      src="@/assets/icons/lighton.png"
      alt="candle-off"
      @click="changeTheme"
    />
    <img
      v-else
      src="@/assets/icons/lightoff.png"
      alt="light-on"
      class="candle-img"
      @click="changeTheme"
    />
    <Header></Header>
  </div>
  <router-view @showBanner="showBanner" />
  <div class="separator"></div>
  <Footer :isdark="isDark"></Footer>
  <div class="banner-container" v-show="isBannerShowing">
    <GDPRBannerVue
      @accept="acceptgdpr"
      @closeBanner="closeBanner"
    ></GDPRBannerVue>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import GDPRBannerVue from "./components/GDPRBanner.vue";
import { useDark } from "@vueuse/core";

export default {
  components: { Header, Footer, GDPRBannerVue },
  head() {
    return {
      description: "ciao",
      ogTitle: "ciao",
    };
  },
  data() {
    return {
      isBannerShowing: true,
      candlePath: this.computeCandlePath,
      isDark: useDark(),
      mobile: false,
      isPlayerOpen: false,
    };
  },
  methods: {
    changeTheme() {
      this.isDark = !this.isDark;
    },
    acceptgdpr() {
      document.cookie = "gdpr=true";
      this.closeBanner();
    },
    closeBanner() {
      this.isBannerShowing = false;
    },
    showBanner() {
      this.isBannerShowing = true;
    },
    togglePlayer() {
      this.isPlayerOpen = !this.isPlayerOpen;
    },
  },
  computed: {
    computeCandlePath() {
      if (this.isDark) {
        return require("@/assets/icons/lighton.png");
      }
      return require("@/assets/icons/lightoff.png");
    },
  },
  beforeMount() {
    if (screen.width < 1024) {
      this.mobile = true;
    }
  },
  mounted() {
    if (document.cookie.includes("gdpr=true")) {
      this.isBannerShowing = false;
    } else {
      this.isBannerShowing = true;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.banner-container {
  width: 99%;
  position: fixed;
  bottom: 2%;
  margin: unset;
}
.player-container {
  position: fixed;
  width: 500px;
  left: 1%;
  top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.spotify-logo {
  width: 64px;
}
.spotify-logo:hover {
  width: 100px;
}
</style>
