<template>
  <Head>
    <meta name="og:title" property="og:title" :content="post.title" />
    <meta
      name="og:description"
      property="og:description"
      :content="post.summary"
    />
    <meta name="og:image" property="og:image" :content="post.featured_image" />
    <meta
      name="og:url"
      property="og:url"
      :content="'https://www.n-bit.it/blogpost/' + post.slug"
    />
  </Head>
  <div id="mathjax-hook" key="latext" v-if="post" class="bp-container">
    <div class="bp-logo-container">
      <img class="bp-cover" :src="parseSrc(post.featured_image)" alt="cover" />
      <div class="bp-title-container">
        <h1 class="bp-title">{{ post.title }}</h1>
        <div class="bp-author-container">
          <img
            :src="post.author.profile_image"
            alt="authorimg"
            class="author-image"
          />
          <div>
            <p>{{ post.author.first_name }} {{ post.author.last_name }}</p>
            <p>nicola.moro@n-bit.it</p>
          </div>
        </div>
        <div class="likes-container">
          <img
            v-if="!likeLeft"
            class="bp-heart-icon"
            src="@/assets/icons/heart-red.webp"
            alt="leave_a_like"
            @click="leaveALike"
          />
          <img
            v-if="likeLeft"
            class="bp-heartlock-icon"
            src="@/assets/icons/heartlock-red.webp"
            alt="leave_a_like"
          />
          <p>{{ numberOfLikes }}</p>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="bp-body-container" v-html="post.body"></div>
    <a href="#"><img src="@/assets/icons/backtotop.png" alt="backtotop" /></a>
    <div class="separator"></div>
    <CommentSection :post="post"></CommentSection>
  </div>
</template>

<script>
import CommentSection from "@/components/CommentSection.vue";
import { VueMathjax } from "vue-mathjax-next";
import { fetchPost, leaveLike, getLikes } from "@/utils/utils";
import { Head } from "@unhead/vue/components";

export default {
  components: { CommentSection, "vue-mathjax": VueMathjax, Head },
  props: ["slug"],
  data() {
    return {
      post: null,
      numberOfLikes: 0,
      likeLeft: false,
    };
  },
  methods: {
    parseSrc(src) {
      return src;
    },
    getLikes() {
      getLikes(this.slug).then((resp) => (this.numberOfLikes = resp));
    },
    leaveALike() {
      leaveLike(this.slug)
        .then((resp) => {
          if (resp) {
            this.likeLeft = true;
          }
        })
        .then(this.getLikes());
    },
  },
  beforeMount() {
    fetchPost(this.slug).then((data) => {
      this.post = data["data"];
    });
  },
  mounted() {
    this.getLikes();
    window.MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  },
};
</script>

<style scoped>
.bp-container {
  gap: unset;
}
.bp-logo-container {
  width: 90%;
  margin-top: var(--space-uunit);
  margin-bottom: var(--space-uuunit);
}
.bp-title-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: var(--space-unit);
}
.bp-title {
  text-align: left;
}
.bp-body-container {
  margin-top: var(--space-uunit);
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bp-body-container :deep(p) {
  margin-top: var(--space-unit);
  line-height: 1.75;
  letter-spacing: 1px;
}

.bp-body-container :deep(pre) {
  text-align: left;
  background-color: rgb(201, 201, 201);
  padding: var(--space-unit) var(--space-uunit);
  border-radius: 10px;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.75;
  color: black;
}
.bp-body-container :deep(code),
span {
  max-width: 99%;
  font-family: "Courier New", Courier, monospace;
  background-color: rgb(201, 201, 201);
  border-radius: 4px;
  padding: 2px;
  color: black;

  flex-wrap: wrap;
  width: fit-content;
  word-break: break-all;
}
.author-image {
  max-width: 75px;
  border-radius: 50%;
}

.bp-body-container :deep(h3) {
  text-align: left;
  margin-top: var(--space-uunit);
  margin-bottom: 5px;
}
.bp-body-container :deep(h4) {
  text-align: left;
  margin-top: var(--space-uunit);
  margin-bottom: 1.75;
}
.bp-body-container :deep(ol, ul) {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
}
.bp-body-container :deep(li) {
  text-align: left;
  line-height: 1.75;
  margin-top: var(--space-unit);
}
.bp-body-container :deep(math) {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: "Ubuntu-Regular";
}
.bp-body-container :deep(pre) {
  overflow: scroll;
}
.bp-body-container :deep(img) {
  max-width: 100%;
  border-radius: 16px;
}
.bp-cover {
  min-width: 450px;
  max-width: 30%;
  border-radius: 16px;
  border: 2px solid var(--color-main);
  box-shadow: -3px 3px 10px var(--color-main-shadow);
}
.bp-author-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  gap: var(--space-uunit);
}
.likes-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .bp-body-container {
    width: 90%;
  }
  .bp-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bp-cover {
    min-width: unset;
    max-width: 80%;
  }
  .bp-title-container {
    align-items: center;
  }
  .bp-title {
    font-size: 40px;
    text-align: center;
  }
}
@media (max-width: 320px) {
  .bp-body-container {
    width: 95%;
  }
}
</style>