<template>
  <div class="mars-outer">
    <div class="logo-container">
      <img src="@/assets/marscard.webp" class="mars-img" />
      <p class="subtitle">Però è difficile che piova</p>
    </div>
    <div class="cards-container">
      <div v-for="wd in weatherData" :key="wd.id" class="wd-card">
        <a href="https://science.nasa.gov/mars/">
          <img
            class="mars-icon"
            src="@/assets/icons/sun.png"
            alt="atmo_opacity_icon"
          />
          <h3>{{ wd.terrestrial_date }} ( Sol {{ wd.sol }} )</h3>
          <h4>Mese marziano: {{ wd.season.slice(5) }}</h4>
          <div style="display: flex; flex-direction: row; align-items: center">
            <img src="@/assets/icons/cold_t.png" alt="cold_t.png" />
            <p>{{ wd.min_temp }}</p>
            <p>&nbsp;&nbsp;&nbsp;</p>
            <img src="@/assets/icons/hot_t.png" alt="cold_t.png" />
            <p>{{ wd.max_temp }}</p>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center">
            <img src="@/assets/icons/sunrise.png" alt="cold_t.png" />
            <p>{{ wd.sunrise }}</p>
            <p>&nbsp;&nbsp;&nbsp;</p>
            <img src="@/assets/icons/sunset.png" alt="cold_t.png" />
            <p>{{ wd.sunset }}</p>
          </div>
          <div class="separator"></div>
          <h4>Mi serve la protezione solare? (UV index)</h4>
          <p style="text-align: center">
            {{ parseUvIndex(wd.local_uv_irradiance_index) }}
          </p>
        </a>
      </div>
    </div>
    <div class="mars-caption">
      <p>
        Due dei temi per me più appassionanti, da sempre, sono di sicuro
        l'astronomia e l'esplorazione spaziale. Penso sia affascinante pensare
        che, tutti noi, viviamo le nostre vite su un
        <a href="https://it.wikipedia.org/wiki/Pale_Blue_Dot"
          >granello di sabbia</a
        >, girando come trottole in un universo tanto grande da non poterlo
        nemmeno comprendere del tutto.
      </p>
      <p>
        E, a maggior ragione, sono fermamente convinto che, la Nasa e le altre
        Agenzie, mettendo a disposizione i dati raccolti dalle varie missioni in
        modo open e aperto a tutti (come molto più spesso si dovrebbe fare,
        invece, in informatica) siano l'esempio perfetto di come tutto possa
        funzionare meglio attraverso la collaborazione, invece che con il
        conflitto.
      </p>
      <p>
        Per questo, dedichiamo questa pagina al rover Curiosity, che dal 2011
        gironzola su Marte raccogliendo dati (di vario tipo) e rispedendoli qui
        a casa. Tra i dati raccolti,<a
          href="https://science.nasa.gov/mission/msl-curiosity/"
          >Curiosity</a
        >
        analizza anche i parametri di riferimento per il meteo, alcuni dei quali
        esposti qui sopra, ovvero:
      </p>
      <ul>
        <p>
          <b>Data terrestre</b>: un giorno sulla terra dura 24 ore, mentre uno
          su Marte circa 24 ore e 40 minuti (ovvero un Sol, cioè un giorno
          solare su Marte). La data indicata è la data sulla Terra in
          corrispondenza del relativo mezzogiorno marziano.;
        </p>
        <p>
          <b>Alba e Tramonto</b>: anche se un giorno dura 40 minuti in più,
          anche Marte ha le sue stagioni e la durata delle ore di luce e buio
          varia durante l'anno;
        </p>
        <p>
          <b>Minima e Massima</b>: l'atmosfera su Marte è molto più rarefatta e
          il pianeta è più distante dal Sole. Quindi l'escursione termica tra
          giorno e notte è molto più pronunciata rispetto a quella sulla Terra;
        </p>
        <p>
          <b>Indice UV</b>: indica l'intensità degli ultravioletti alla
          posizione di Curiosity nel momento del rilevamento. Qui sulla Terra i
          dannosi raggi UV vengono bloccati dallo strato di ozono
          nell'atmosfera, ma su Marte, essendo essa molto più rarefatta, i raggi
          UV arrivano belli carichi a destinazione. Portatevi la crema solare,
          in caso andaste!
        </p>
      </ul>
      <p>
        Nota: ovviamente queste non sono previsioni e i dati raccolti dal povero
        Curiosity ci mettono un po' a tornare qui, venire correttamente
        elaborati e formattati. E' scontato, quindi, avere qualche giorno di
        ritardo sui dati. Avremmo inoltre potuto utlizzare i dati di
        <a href="https://science.nasa.gov/mission/mars-2020-perseverance/"
          >Perseverance</a
        >, altra missione lanciata su Marte ma molto più di recente (2020).
        Tuttavia ho preferito utilizzare quelli di Curiosity, almeno finchè sarà
        possibile, come tributo all'ottimo lavoro svolto fino ad ora!
      </p>
      <br />
      <p>
        <i
          >All the credits go to the amazing job of:
          <a href="https://www.nasa.gov/">NASA</a> ,
          <a href="https://www.jpl.nasa.gov/">JPL (Jet Propulsion Labs)</a> ,
          <a href="https://cab.inta-csic.es/">CAB (Centro de astrobiologìa)</a>
        </i>
        <br />
        <i
          >Cover by
          <a href="https://www.freepik.com/icon/mars_2277571">Freepik</a></i
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weatherData: [],
    };
  },
  methods: {
    parseUvIndex(uvindex) {
      if (uvindex.includes("High")) {
        return "Ti serve il cappello di piombo più che altro (Alto)";
      }
      if (uvindex.includes("Moderate")) {
        return "Si, protezione 60 basterà (Moderato)";
      } else {
        return "Un cappellino dovrebbe bastare...forse (Basso)";
      }
    },
  },

  mounted() {
    var jsondata = fetch(
      "https://mars.nasa.gov/rss/api/?feed=weather&category=msl&feedtype=json"
    )
      .then((response) => response.json())
      .then((data) => {
        for (let i = 0; i < 7; i++) {
          this.weatherData.push(data["soles"][i]);
        }
      });
  },
};
</script>

<style>
a {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-unit);
}
p {
  text-align: justify;
  line-height: 1.75;
  word-spacing: 2px;
}
ul {
  display: flex;
  flex-direction: column;
  gap: var(--space-unit);
  list-style-type: circle;
  padding: unset;
}
.mars-outer {
  margin-top: var(--space-uunit);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-uunit);
}
.cards-container {
  display: flex;
  flex-direction: row;
  width: unset;
  max-width: 80%;
  gap: var(--space-unit);
  justify-content: center;
  flex-wrap: wrap;
}
.mars-img {
  width: 50%;
}
.mars-icon {
  width: 15%;
}
.subtitle {
  font-family: "Ubuntu-SemiBold";
  text-align: center;
}
.wd-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--space-unit);
  border: 1px solid var(--color-main);
  border-radius: 16px;
  padding: var(--space-unit);
}
.mars-caption {
  width: 80%;
  text-align: justify;
}
</style>