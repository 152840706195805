<template>
  <div class="secret-outer" ref="secretouter">
    <video src="@/assets/ranggyu.mp4" autoplay></video>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
};
</script>

<style>
.secret-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-unit);
  margin-top: var(--space-unit);
  gap: var(--space-unit);
}
.still-here {
  margin-top: var(--space-unit);
  font-family: "Ubuntu-SemiBold";
  line-height: 1.75;
}
</style>