<template>
  <div class="whoami-outer">
    <h1>$ whoami</h1>
    <div class="card" :class="{ mobile: isMobile }">
      <p style="text-align: center">
        Mi chiamo Nicola e mi occupo di programmazione e sviluppo software da
        oltre 5 anni. Nel tempo libero mantengo questo blog di informatica,
        algoritmi e matematica. Trovi gli articoli qui a fianco: fammi sapere
        cosa ne pensi! (Li trovi anche in inglese su
        <a href="https://medium.com/@nicola.moro2312">Medium</a>)
      </p>
    </div>

    <Socials></Socials>
    <div class="separator"></div>
    <a class="social-link" href="https://projecteuler.net/">
      <img
        src="https://projecteuler.net/profile/NicolaM94.png"
        alt="peprofileimg"
      />
    </a>
    <router-link to="/meteomars" class="social-link" href="/meteomars"
      ><img
        style="width: 70%"
        src="@/assets/icons/meteomarslabel.webp"
        alt="meteomars"
    /></router-link>
    <router-link to="/suggestedbooks" class="social-link" href="/suggestedbooks"
      ><img
        style="width: 70%"
        src="@/assets/icons/books-label.webp"
        alt="suggestedbooks"
    /></router-link>
  </div>
</template>

<script>
import Socials from "@/components/Socials.vue";

export default {
  components: {
    Socials,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  beforeMount() {
    if (screen.width <= 1024) {
      this.isMobile = true;
    }
  },
};
</script>

<style scoped>
p {
  text-align: justify;
}

.mobile {
  flex-direction: column;
}

.whoami-outer {
  margin-top: var(--space-unit);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-unit);
  gap: var(--space-uunit);
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-uunit);
}

.social-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.social-link {
  display: contents;
}
</style>