<template>
  <div class="projects-outer" v-if="!finished">
    <img src="@/assets/icons/hourglass.png" alt=" clessidra">
    <h1>Pagina in fase di modifica.</h1>
    <h3>Stay tuna!</h3>
    <iframe src="https://giphy.com/embed/26FxP5WOBDNUI0hKo" width="480" height="269" style="border-radius: 10px;" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/greenpeace-tuna-26FxP5WOBDNUI0hKo">via GIPHY</a></p>
  </div>
  <div class="projects-outer" v-if="finished">
    <p>
      Ecco alcuni dei progetti su cui lavoro <br />
      (alcuni sono in fase di costruzione, possono cambiare spesso)
    </p>
    <div class="cards-container">
      <div v-for="c in collectedData" :key="c.id" class="project-card">
        <img class="project-img" :src="c.img" alt="" />
        <h3>{{ c.name }}</h3>
        <p>{{ c.caption }}</p>
        <div class="project-card-links-container">
          <a :href="l.url" v-for="l in c.link" :key="l.url">{{ l.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collectedData: [],
      finished : false
    };
  },
  mounted() {
    var metadata = require.context("@/assets/", false, /\.json$/i);
    metadata
      .keys()
      .map(metadata)[0]
      .forEach((element) => {
        this.collectedData.push(element);
      });
  },
};
</script>

<style scoped>
p {
  text-align: center;
}

.projects-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-unit);
  margin-top: var(--space-unit);
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: var(--space-unit);
  width: 90%;
  gap: var(--space-unit);
}
.project-card {
  border-radius: 16px;

  padding: var(--space-unit);
  margin: var(--space-unit);
  border: 2px solid var(--color-main-border);
  box-shadow: 2px 2px 4px var(--color-main-shadow);
  display: flex;
  flex-direction: column;
  gap: var(--space-unit);
  align-items: center;
}
.project-img {
  width: 200px;
  border-radius: 10px;
}
.project-card-links-container {
  display: flex;
  margin: auto var(--space-unit);
  flex-direction: row;
  justify-content: space-evenly;
  gap: var(--space-uunit);
}
</style>