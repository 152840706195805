<template>
  <div class="commentsection-outer">
    <div class="comment-preform">
      <h2>Lascia qui un saluto o un commento!</h2>
    </div>

    <form
      v-if="commentsEnabled"
      @submit.prevent="commentButtonFunc"
      class="comment-form"
    >
      <input
        type="text"
        class="comment-mail"
        placeholder="Nome da visualizzare"
        v-model="nameField"
        required
      />
      <textarea
        class="comment-text"
        placeholder="Il tuo messaggio.."
        rows="8"
        v-model="commentField"
        required
      ></textarea>
      <p v-if="volgFound" style="color: red">
        Niente volgarità, insulti e scripting. Grazie!
      </p>
      <p v-if="serverError">
        Sembra ci sia un problema con il server, riprova più tardi!
      </p>
      <button>Commenta</button>
    </form>

    <div v-if="!commentsEnabled">
      <h2
        style="
          border: 1px solid crimson;
          border-radius: 16px;
          padding: 10px;
          color: coral;
        "
      >
        La sezione commenti è in arrivo! Stay tuned!
      </h2>
    </div>
  </div>
  <div class="previouscomments">
    <CommentCard :comment="c" v-for="c in comments" :key="c.Id"></CommentCard>
  </div>
</template>

<script>
import {
  profanityFilter,
  recaptchaFilter,
  postComment,
  fetchList,
  fetchComments,
} from "@/utils/utils";

import CommentCard from "./CommentCard.vue";

export default {
  props: ["post"],
  components: { CommentCard },
  data() {
    return {
      comments: [],
      nameField: "",
      commentField: "",
      volgFound: false,
      serverError: false,
      commentsEnabled: true,
    };
  },
  methods: {
    async commentButtonFunc() {
      console.log("Comment fired");
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("submit");
      console.log(token);

      const recaptchaCheck = await recaptchaFilter(token);

      if (!recaptchaCheck) {
        return;
      }

      const commentControl = await profanityFilter(this.commentField);
      // Anti profanity filter not working
      if (commentControl === undefined) {
        this.serverError = true;
        return;
      }
      // Profanity found
      if (commentControl) {
        this.volgFound = true;
        this.serverError = false;
        return;
      }
      // Profanity not found
      else {
        this.volgFound = false;
        // Send comment to db
        console.log("Filtering success. Posting...");
        var postSuccess = postComment(
          this.post.slug,
          this.nameField,
          this.commentField
        );

        postSuccess.then((resp) => {
          if (resp) {
            this.serverError = false;
            this.getComments();
          } else {
            this.serverError = true;
          }
        });
      }
    },
    // get comments
    getComments() {
      fetchComments(this.post.slug).then((data) => (this.comments = data));
    },
  },
  mounted() {
    this.getComments();
  },
};
</script>

<style scoped>
p {
  text-align: center;
}

.previouscomments {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: var(--space-uuuunit);
  gap: var(--space-uunit);
  margin-bottom: var(--space-uuunit);
}
.commentsection-outer {
  margin-top: var(--space-uuunit);
  display: flex;
  flex-direction: column;
  gap: var(--space-uuunit);
  align-items: center;
}
.comment-preform {
  display: flex;
  flex-direction: column;
  gap: var(--space-uuunit);
  justify-content: center;
}
.comment-form {
  display: flex;
  flex-direction: column;
  gap: var(--space-uunit);
  justify-content: space-evenly;
  align-items: center;
}
</style>