import { createRouter, createWebHistory } from 'vue-router'
import Blog from '../views/Blog.vue'
import Contacts from "@/views/Contacts.vue"
import Projects from "@/views/Projects.vue"
import SecretPage from "@/views/SecretPage.vue"
import MarsWeather from "@/views/MarsWeather.vue"
import NotFound from '@/views/NotFound.vue'
import SuggestedBooks from '@/views/SuggestedBooks.vue'
import PrivacyRules from '@/views/PrivacyRules.vue'
import BlogPost from '@/views/BlogPost.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Blog
  },
  {
    path: "/contacts",
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/secretpage',
    name: ':D',
    component: SecretPage
  },
  {
    path: '/meteomars',
    name: "MeteoMars",
    component: MarsWeather
  },
  {
    path: '/suggestedbooks',
    name: 'SuggestedBooks',
    component: SuggestedBooks
  },
  {
    path: '/gdpr',
    name: "GDPR",
    component: PrivacyRules
  },
  {
    path: '/:catchAll(.*)',
    name: "404",
    component: NotFound
  },
  {
    path: '/blogpost/:slug',
    name: 'BlogPost',
    component: BlogPost,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((toRoute, _fromRoute, next) => {
  window.document.title = 'N-bit.it - ' + toRoute.name
  next()
})


export default router
