<template>
  <div class="banner">
    <div class="banner-logo">
      <h1>Biscottino?</h1>
      <img style="width: 44px" src="@/assets/cookie.png" alt="" />
    </div>
    <p>
      Questa è la brutta barra blu della legge, che ti informa che il sito
      utilizza cookies tecnici e tecnologie simili per offrirti un'esperienza
      non dico bella, ma almeno interattiva sul sito. <br />
      Per quanto tu non abbia bisogno di inserire dati personali all'interno del
      sito, la legge e il buon senso mi obbligano ad avvisarti. Continuando con
      la navigazione quindi, confermi di accettare le condizioni di utilizzo del
      servizio. <br />
      Nel caso volessi maggiori informazioni in merito, clicca sul pulsante qui
      sotto o visita la pagina <a href="/gdpr">GDPR, Privacy and Rules</a>.
    </p>
    <div class="button-container">
      <button @click="gotoGDPR">Voglio saperne di più!</button>
      <button @click="acceptCookies">Accetta e prosegui</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoGDPR() {
      this.$router.push({ path: "/gdpr" });
      this.$emit("closeBanner");
    },
    acceptCookies() {
      this.$emit("accept");
    },
  },
};
</script>

<style scoped>
p {
  color: white;
  line-height: 2;
}
a {
  color: coral;
}
h1 {
  color: rgb(255, 255, 255);
}
.banner {
  padding: var(--space-unit);
  background-color: rgba(5, 28, 71, 0.918);
  border: 3px solid rgb(0, 17, 49);
  border-radius: 16px;
  display: flex;
  gap: var(--space-unit);
  flex-direction: column;
  align-items: flex-start;
}
.banner-logo {
  display: flex;
  flex-direction: row;
  gap: var(--space-uunit);
  align-items: center;
}
button {
  width: 200px;
  font-size: 14px;
}
.button-container {
  width: 100%;
  display: flex;
  gap: var(--space-unit);
}
</style>